footer {
  background-color: #1a1a1a;
  padding-bottom: 10px;
}
.div-footer-logo {
  text-align: center;
}

.footer-logo {
  width: 300px;
}

.copyright {
  text-align: center;
}
